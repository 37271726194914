// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".checkbox--text--4wv96GEs{color:#cccccc !important}.checkbox--check--SF8RnNNs{border-color:#ffffff !important;background-color:transparent !important}.checkbox--check--SF8RnNNs.checkbox--checked--1_zxeJYn{color:#303030 !important;background-color:#EB6B06 !important;border-color:#EB6B06 !important}.checkbox--check--SF8RnNNs.checkbox--checked--1_zxeJYn:after{border-color:unset}\n", ""]);
// Exports
exports.locals = {
	"text": "checkbox--text--4wv96GEs",
	"check": "checkbox--check--SF8RnNNs",
	"checked": "checkbox--checked--1_zxeJYn"
};
module.exports = exports;
