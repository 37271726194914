'use strict';

import baseConfig from './base';

const awsApi = 'https://escapestudios.api.smashcut.com';
const awsWss =
  'wss://9x7pww7hyg.execute-api.eu-west-1.amazonaws.com/escapestudios';

let config = {
  apolloUri: `${awsApi}/graphql`,
  appEnv: 'aws-escapestudios',
  baseUrl: 'https://escapestudios.smashcut.com',
  env: 'prod',
  logrocket: 'px1cem/es-smashcut-web',
  logRocketEnabled: true,
  opentokApiKey: '47109624',
  publicSiteUrl: 'https://www.smashcut.com',
  pushMessagingPublicVapidKey:
    'BNbrGbLhAS3_1dSWCnyJXgMFzHkY5f8nWnKemnak0XvIAa9ciQtfhWY_NoI1x91vDMID1mM488YUyHEofYfgWpk',
  sentry:
    'https://c245c4c53a6d4b589a4824c460298651@o82327.ingest.sentry.io/179879',
  sentryEnabled: true,
  smashcutApiUrl: awsApi,
  wssUrl: awsWss
};

let mergedConfig = Object.freeze(Object.assign({}, baseConfig, config));
// console.log('Using config: ', mergedConfig)
export default mergedConfig;
