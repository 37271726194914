import React from 'react';
import styles from './CenteredLogo.scss';
import { FullLogo } from './FullLogo';

const CenteredLogo = ({ children }) => (
  <div className={styles.centeredLogo}>
    <div className={styles.logoBackground}>
      <FullLogo className={styles.logoImage} />
    </div>
    {children}
  </div>
);

export default CenteredLogo;
