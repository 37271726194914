// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DashboardTabCrewsTabsTheme--pointer--3uGIwKKk{background-color:#EB6B06 !important}.DashboardTabCrewsTabsTheme--navigation--2rY0ngPv{-webkit-box-shadow:none !important;box-shadow:none !important;background:#2d2d2d;overflow:visible;overflow:initial}@media (max-width: 1000px){.DashboardTabCrewsTabsTheme--navigation--2rY0ngPv{display:none}}.DashboardTabCrewsTabsTheme--cica--3hBEgVaf .DashboardTabCrewsTabsTheme--navigation--2rY0ngPv{display:none}.DashboardTabCrewsTabsTheme--tabs--2IaC4fgl{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.DashboardTabCrewsTabsTheme--tab--IkCLbax3{padding:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;overflow:visible;overflow:initial}.DashboardTabCrewsTabsTheme--label--Qe9K3Tet{padding:20px 20px}@media (max-width: 1000px){section{position:static !important;position:initial !important}}section:focus{outline:-webkit-focus-ring-color auto 5px !important}\n", ""]);
// Exports
exports.locals = {
	"pointer": "DashboardTabCrewsTabsTheme--pointer--3uGIwKKk",
	"navigation": "DashboardTabCrewsTabsTheme--navigation--2rY0ngPv",
	"cica": "DashboardTabCrewsTabsTheme--cica--3hBEgVaf",
	"tabs": "DashboardTabCrewsTabsTheme--tabs--2IaC4fgl",
	"tab": "DashboardTabCrewsTabsTheme--tab--IkCLbax3",
	"label": "DashboardTabCrewsTabsTheme--label--Qe9K3Tet"
};
module.exports = exports;
