import React from 'react';
import styles from './LoginPageLogo.scss';
import { FullLogo } from './FullLogo';

const LoginPageLogo = () => (
  <div className={styles.loginPageLogo}>
    <FullLogo className={styles.logo} />
  </div>
);

export default LoginPageLogo;
